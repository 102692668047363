.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'],
  input[type='radio'] {
    --active: #275EFE;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 33px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #60A5FA;
    background: var(--b, var(--background));
    -webkit-transition: background .3s, border-color .3s, -webkit-box-shadow .2s;
    transition: background .3s, border-color .3s, -webkit-box-shadow .2s;
    transition: background .3s, border-color .3s, box-shadow .2s;
    transition: background .3s, border-color .3s, box-shadow .2s, -webkit-box-shadow .2s;
  }
  input[type='checkbox']:after,
  input[type='radio']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    -webkit-transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s), -webkit-transform var(--d-t, 0.3s) var(--d-t-e, ease);
  }
  input[type='checkbox']:checked,
  input[type='radio']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type='checkbox']:disabled,
  input[type='radio']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: .9;
  }

  input[type='checkbox']:disabled:checked,
  input[type='radio']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type='checkbox']:disabled + label,
  input[type='radio']:disabled + label {
    cursor: not-allowed;
  }
  input[type='checkbox']:hover:not(:checked):not(:disabled),
  input[type='radio']:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type='checkbox']:focus,
  input[type='radio']:focus {
    -webkit-box-shadow: 0 0 0 var(--focus);
            box-shadow: 0 0 0 var(--focus);
  }
  input[type='checkbox']:not(.switch),
  input[type='radio']:not(.switch) {
    width: 33px;
  }
  input[type='checkbox']:not(.switch):after,
  input[type='radio']:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type='checkbox']:not(.switch):checked,
  input[type='radio']:not(.switch):checked {
    --o: 1;
  }
  input[type='checkbox'] + label,
  input[type='radio'] + label {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
  input[type='checkbox']:not(.switch) {
    border-radius: 4px;
  }
  input[type='checkbox']:not(.switch):after {
    width: 8px;
    height: 18px;
    border: 3px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 11px;
    top: 4px;
    -webkit-transform: rotate(var(--r, 20deg));
            transform: rotate(var(--r, 20deg));
  }
  input[type='checkbox']:not(.switch):checked {
    --r: 43deg;
  }
  input[type='checkbox'].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type='checkbox'].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background: var(--ab, var(--border));
    -webkit-transform: translateX(var(--x, 0));
            transform: translateX(var(--x, 0));
  }
  input[type='checkbox'].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type='checkbox'].switch:disabled:not(:checked):after {
    opacity: .6;
  }
  input[type='radio'] {
    border-radius: 50%;
  }
  input[type='radio']:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    -webkit-transform: scale(var(--s, 0.7));
            transform: scale(var(--s, 0.7));
  }
  input[type='radio']:checked {
    --s: .5;
  }
}

    
.switch{
  left:-50%;
}



/* scroll bar */


/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 20px;
  cursor:not-allowed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border: 2px solid #fff;
  border-radius: 20px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
