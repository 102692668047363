/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  @font-face {
    font-family: Gilroy;
    font-weight: 400;
    src: url(assets/fonts/gr.woff2) format("woff2");
  }


  @font-face {
    font-family: Gilroy;
    font-weight: 500;
    src: url(assets/fonts/gm.woff2) format("woff2");
  }

  
  @font-face {
    font-family: Gilroy;
    font-weight: 700;
    src: url(assets/fonts/gs.woff2) format("woff2");
  }

    
  @font-face {
    font-family: Gilroy;
    font-weight: 900;
    src: url(assets/fonts/gb.woff2) format("woff2");
  }


}



body {
  margin: 0;
  font-family: Gilroy, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






.small-spinner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%,#474bff);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3.8px),#000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}




@keyframes spinner-zp9dbg {
   to {
      transform: rotate(1turn);
   }
}


.yellowSpinner{
  background: conic-gradient(#0000 10%,#eab308);
}

.redSpinner{
  background: conic-gradient(#0000 10%,#f43f5e);
}

.blueSpinner{
  background: conic-gradient(#0000 10%,#1d4ed8);
}


.greenSpinner {
  background: conic-gradient(#0000 10%,#16a34a);

}